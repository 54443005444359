(function() {
    'use strict';

    angular.module('adminApp')
        .controller("manageSystemLockCtrl", manageSystemLockController);

    manageSystemLockController.$inject = ["$scope", "$uibModal", "Notification", "aerosAdminApi"];

    function manageSystemLockController($scope, $uibModal, Notification, aerosAdminApi) {

        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;

            if (!$scope.allow['editSystemLock']) return $scope.reject();

            function getSystemLockTypes () {
                aerosAdminApi.getSystemLockTypes()
                    .success(function (data) {
                        $scope.lockTypes = data.lockTypes;
                    })
                    .error(function (err) {
                        Notification.error("Error fetching system lock types: " + err.message);
                    });
            }

            (function init() {
                getSystemLockTypes();
            }() );

            $scope.lock = function(lockType){
                aerosAdminApi.lockSystemType(lockType.type)
                    .success(function (){
                        getSystemLockTypes();
                    })
                    .error(function (err) {
                        Notification.error("Error locking system type: " + err.message);
                    });
            };

            $scope.unlock = function(lockType){
                aerosAdminApi.unlockSystemType(lockType.type)
                    .success(function (){
                        getSystemLockTypes();
                    })
                    .error(function (err) {
                        Notification.error("Error unlocking system type: " + err.message);
                    });
            }
        });
    }

})();

